require('./vue/vue');
import MobileAccordion from "./components/MobileAccordion.component";
import SingleProductCarousel from "./components/SingleProductCarousel.component";
import FeaturedProductCarousel from "./components/FeaturedProductCarousel.component";
import ProductCardCarousel from "./components/ProductCardCarousel.component";

const components = [
    {
        class: MobileAccordion,
        selector: '.mobile-accordion__button'
    },
    {
        class: SingleProductCarousel,
        selector: '.product-carousel',
    },
    {
        class: ProductCardCarousel,
        selector: '.product-card-swiper',
    },
    {
        class: FeaturedProductCarousel,
        selector: '.js-featured-product-swiper',
    },
];

components.forEach(component => {
    if (document.querySelector(component.selector) !== null) {
        document.querySelectorAll(component.selector).forEach(
            element => new component.class(element, component.options)
        );
    }
});

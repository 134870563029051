<template>
  <div style="height: 500px; width: 100%">
    <l-map
        v-if="showMap"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        style="height: 80%"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
    >
      <v-geosearch :options="geosearchOptions" ></v-geosearch>
      <l-tile-layer
          :url="url"
          :attribution="attribution"
      />
      <l-marker v-if="isStoresLoaded" v-for="(item, index) in stores" :key="index" :lat-lng="item.latLng" :icon="icon">
        <l-popup>
          <div><b>{{ item.title }}</b></div>
          <div>{{ item.street }}</div>
          <div>{{ item.city }}</div>
          <div>{{ item.phone }}</div>
          <div>{{ item.email }}</div>
        </l-popup>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon} from "vue2-leaflet";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import VGeosearch from 'vue2-leaflet-geosearch';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/assets/css/leaflet.css';
import axios from "axios";
export default {
  name: 'ContactMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
    VGeosearch
  },
  data() {
    return {
      zoom: 10,
      center: latLng(45.7766003, 15.9707448),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(45.7766381, 15.9730211),
      currentZoom: 10,
      currentCenter: latLng(45.7766003, 15.9707448),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      icon: L.icon({
        iconUrl: '/build/images/marker-icon.png',
        iconSize: [25, 41],
      }),
      showMap: true,
      stores: [],
      isStoresLoaded: false,
      geosearchOptions: {
        provider: new OpenStreetMapProvider(),
        autoClose: true,
        searchLabel: 'Upišite grad/mjesto/adresu',
        position: 'topleft'
      },
    }
  },
  mounted() {
    axios.get(`${window.location.origin}/api/v1/stores`).then(response => {
      this.stores = [...response.data]
      this.stores.forEach(store => {
        console.log(store.lat);
        if (store.lat) {
          store.latLng = latLng(parseFloat(store.lat), parseFloat(store.lng))
        }
      })
      this.isStoresLoaded = true
    })
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    }
  }
}
</script>

<style lang="scss">
.geosearch.active {
  form {
    display: flex !important;
    flex-wrap: wrap;

    input {
      width: 90% !important;
    }

    a {
      width: 10% !important;
      display: none;
    }
  }

  //.leaflet-control-geosearch .results.active {
  //  width: 100%;
  //}
}
</style>
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default class ProductCardCarousel {
    constructor() {
        this.onInit()
    }

    onInit() {
        new Swiper('.product-card-swiper', {
            modules: [Pagination],
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 0,
            initialSlide: 0,
            autoHeight: true,
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                // when window width is >= 480px
                576: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
        });
    }
}

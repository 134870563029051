<template>
  <div id="delivery-form" class="delivery-form">

    <div v-if="showBarfMsg" class="delivery__alert">
      <p>Za sada su BARF (smrznuti) proizvodi dostupni za narudžbu na području Zagreba i Zagrebačke županije.</p>
      <br>
      <p>Kako bi nastavili svoju kupnju, potrebno je ukloniti BARF smrznute proizvod iz košarice.
      Dok mi rješavamo dostavu smrznutog dijela asortimana, neka vaš pas krati
      vrijeme i uživa žvačući naše prirodne i slasne poslastice.“</p>
      <br>
      <a :href="removeBarfUrl">Ukloni BARF smrznute proizvode</a>
    </div>

    <div class="s-form-group">
      <label for="checkout_place" class="s-form-group__label">Država *</label>
      <select v-model="selectedCountry"
              @change="onSelectCountryChange($event)"
              class="s-form-group__input">
        <option disabled >Molimo odaberite državu</option>
        <option v-for="(country, key) in countries"
                :key="key">
          {{ country }}</option>
      </select>
    </div>

    <div v-if="selectedCountry === 'Hrvatska'" class="s-form-group">
      <label for="checkout_place" class="s-form-group__label">Mjesto *</label>
      <select v-model="selectedPostalCode"
              @change="onSelectedPostalCodeChange($event)"
              class="s-form-group__input">
        <option disabled >Molimo odaberite mjesto</option>
        <option v-for="(postalCode, key) in postalCodes"
                :key="key">
          {{ postalCode }}</option>
      </select>
    </div>

    <div v-if="selectedCountry !== 'Hrvatska' && selectedCountry !== 'Molimo odaberite državu'" class="s-form-group">
      <label for="checkout_place" class="s-form-group__label">Mjesto *</label>
      <input type="text"
             v-model="place"
             required="required"
             placeholder="Unesite vaš poštanski broj"
             class="s-form-group__input">
    </div>

    <div class="s-form-group">
      <label for="checkout_place" class="s-form-group__label">Adresa *</label>
      <input type="text"
             v-model="address"
             required="required"
             placeholder="Unesite vašu adresu dostave"
             class="s-form-group__input">
    </div>

    <div v-if="validationMsg" class="delivery__alert">
      <p>{{ validationMsg }}</p>
    </div>

    <div class="s-form-group">
      <a v-if="parseFloat(minimalOrderCost) >= parseFloat(cartTotal)" href="/proizvodi" style="margin-right: calc(100% - 390px);">Povratak na listu proizvoda</a>
      <button class="vb-button vb-button--go-to-checkout"
              :disabled="parseFloat(minimalOrderCost) >= parseFloat(cartTotal)"
              @click="finishOrder"><strong>Dovršite narudžbu</strong></button>
    </div>

    <div v-if="parseFloat(minimalOrderCost) >= parseFloat(cartTotal)" class="delivery__alert">
      <p>Moguća narudžba samo za kupovinu iznad {{ parseFloat(minimalOrderCost) }} €</p>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: "DeliveryForm",
  props: {
    productIds: {
      type: String,
      required: true
    },
    barfProductIds: {
      type: String,
      required: true
    },
    cartId: {
      type: String,
      required: true
    },
    cartTotal: {
      type: String,
      required: true
    },
    isBarfProductInCart: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      ids: [],
      barfIds: [],
      barfInCart: false,

      countries: [],
      allCountries: [
          'Hrvatska',
          'Austrija',
          'Češka',
          'Njemačka',
          'Estonija',
          'Mađarska',
          'Italija',
          'Litva',
          'Latvija',
          'Slovačka',
          'Slovenija'
      ],
      barfCountries: [
        'Hrvatska'
      ],
      selectedCountry: 'Molimo odaberite državu',
      place: '',

      postalCodes: [],
      allPostalCodes: [
        'Zagreb (10000)',
        'Zagreb-Dubrava (10040)',
        'Zagreb-Novi Zagreb (10020)',
        'Zagreb-Sloboština (10010)',
        'Zagreb-Susedgrad (10090)',
        'Aljmaš (31205)',
        'Antunovac (31216)',
        'Aržano (21246)',
        'Babina Greda (32276)',
        'Babino Polje (20225)',
        'Baderna (52445)',
        'Badljevina (34552)',
        'Bakar (51222)',
        'Bakarac (51261)',
        'Bale (Valle) (52211)',
        'Banova Jaruga (44321)',
        'Banovci (32247)',
        'Bapska (32235)',
        'Baranjsko Petrovo Selo (31322)',
        'Barban (52207)',
        'Barilović (47252)',
        'Baška (51523)',
        'Baška Voda (21320)',
        'Batina (31306)',
        'Bebrina (35254)',
        'Bedekovčina (49221)',
        'Bedenica (10381)',
        'Bednja (42253)',
        'Bektež (34343)',
        'Belec (49254)',
        'Belej (51555)',
        'Beli (51559)',
        'Beli Manastir (31300)',
        'Belica (40319)',
        'Belišće (31551)',
        'Belovar (10363)',
        'Benkovac (23420)',
        'Berek (43232)',
        'Beretinec (42201)',
        'Bestovje (10437)',
        'Betina (22244)',
        'Bibinje (23205)',
        'Bijelo Brdo (31204)',
        'Bilje (31327)',
        'Biograd na moru (23210)',
        'Bisag (42226)',
        'Bizovac (31222)',
        'Bjelina (23421)',
        'Bjelovar (43000)',
        'Blace (20357)',
        'Blato (20271)',
        'Blato na Cetini (21254)',
        'Blinja (44231)',
        'Blinjski Kut (44211)',
        'Blizna Donja (21228)',
        'Bobota (32225)',
        'Bobovac (44214)',
        'Bogomolje (21468)',
        'Bol (21420)',
        'Bolman (31323)',
        'Boljun (52434)',
        'Boraja (22206)',
        'Borovo (32227)',
        'Bosiljevo (47251)',
        'Bošnjaci (32275)',
        'Bović (44414)',
        'Božava (23286)',
        'Bračevci (31423)',
        'Branjin Vrh (31301)',
        'Brbinj (23285)',
        'Brđani Šamarički (44441)',
        'Bregana (10432)',
        'Brela (21322)',
        'Brestovac (34322)',
        'Brestovec Orehovički (49228)',
        'Breznica Našička (31225)',
        'Breznički Hum (42225)',
        'Brezovica (10257)',
        'Bribir (51253)',
        'Bribir(kod Skradina) (22223)',
        'Brinje (53260)',
        'Brod Moravice (51312)',
        'Brod na Kupi (51301)',
        'Brodski Stupnik (35253)',
        'Brođanci (31223)',
        'Brseč (51418)',
        'Bršadin (32222)',
        'Brtonigla (Verteneglio) (52474)',
        'Brusje (21454)',
        'Brušane (53206)',
        'Bruvno (23441)',
        'Bučje (34553)',
        'Budimci (31432)',
        'Budinšćina (49284)',
        'Buje (Buie) (52460)',
        'Bukovlje (35209)',
        'Bulinac (43273)',
        'Bunić (53235)',
        'Buševec (10417)',
        'Buzet (52420)',
        'Cabuna (33412)',
        'Carevdar (48263)',
        'Cavtat (20210)',
        'Cerna (32272)',
        'Cernik (35404)',
        'Cerovlje (52402)',
        'Cestica (42208)',
        'Cetingrad (47222)',
        'Cirkvena (48213)',
        'Cista Provo (21256)',
        'Cista Velika (21244)',
        'Cres (51557)',
        'Crikvenica (51260)',
        'Crivac (21229)',
        'Crnac (33507)',
        'Crni Lug (51317)',
        'Čabar (51306)',
        'Čačinci (33514)',
        'Čađavica (33523)',
        'Čaglin (34350)',
        'Čakovci (32238)',
        'Čakovec (40000)',
        'Čara (20273)',
        'Čavle (51219)',
        'Čazma (43240)',
        'Čeminac (31325)',
        'Čepin (31431)',
        'Červar Porat (52449)',
        'Čilipi (20213)',
        'Čista Velika (22214)',
        'Črnkovci (31553)',
        'Ćeralije (33521)',
        'Ćunski (51564)',
        'Dabar (53222)',
        'Dalj (31226)',
        'Darda (31326)',
        'Daruvar (43500)',
        'Davor (35425)',
        'Dekanovec (40318)',
        'Delnice (51300)',
        'Desinić (49216)',
        'Dežanovac (43506)',
        'Dicmo (21232)',
        'Divuša (44435)',
        'Dobrinj (51514)',
        'Doli (20231)',
        'Doljani (53252)',
        'Donja Bačuga (44205)',
        'Donja Bistra (10298)',
        'Donja Dubrava (40328)',
        'Donja Lomnica (10412)',
        'Donja Motičina (31513)',
        'Donja Pušća (10294)',
        'Donja Stubica (49240)',
        'Donja Višnjica (42255)',
        'Donja Voća (42245)',
        'Donja Zelina (10382)',
        'Donje Ogorje (21206)',
        'Donje Pazarište (53213)',
        'Donji Andrijevci (35214)',
        'Donji Dolac (21205)',
        'Donji Dragonožec (10253)',
        'Donji Kraljevec (40320)',
        'Donji Kukuruzari (44431)',
        'Donji Lapac (53250)',
        'Donji Martijanec (42232)',
        'Donji Miholjac (31540)',
        'Donji Muć (21203)',
        'Donji Proložac (21264)',
        'Donji Vidovec (40327)',
        'Donji Žirovac (44443)',
        'Draga Bašćanska (51522)',
        'Dragalić (35428)',
        'Draganići (47201)',
        'Dragljane (21275)',
        'Dramalj (51265)',
        'Draškovec (40325)',
        'Drašnice (21328)',
        'Draž (31305)',
        'Dražice (51218)',
        'Drenovci (32257)',
        'Drenje (31418)',
        'Drežnica (47313)',
        'Drežnik Grad (47246)',
        'Drinovci (22324)',
        'Drivenik (51242)',
        'Drniš (22320)',
        'Drnje (48322)',
        'Drvenik (21333)',
        'Drvenik Veliki (21225)',
        'Duboševica (31304)',
        'Dubranec (10418)',
        'Dubrava (10342)',
        'Dubravica (10293)',
        'Dubravka (20216)',
        'Dubrovnik (20000)',
        'Duga Resa (47250)',
        'Dugi Rat (21315)',
        'Dugo Selo (10370)',
        'Dugopolje (21204)',
        'Dvor (44440)',
        'Đakovo (31400)',
        'Đelekovec (48316)',
        'Đeletovci (32244)',
        'Đevrske (22319)',
        'Đulovac (43532)',
        'Đurđenovac (31511)',
        'Đurđevac (48350)',
        'Đurići (32263)',
        'Đurmanec (49225)',
        'Erdut (31206)',
        'Ernestinovo (31215)',
        'Ervenik (22306)',
        'Farkaševac (10344)',
        'Fažana (52212)',
        'Ferdinandovac (48356)',
        'Feričanci (31512)',
        'Funtana (52452)',
        'Fužine (51322)',
        'Gaboš (32212)',
        'Galižana (Gallesano) (52216)',
        'Garčin (35212)',
        'Garešnica (43280)',
        'Gat (31554)',
        'Gata (21253)',
        'Gdinj (21467)',
        'Generalski Stol (47262)',
        'Gerovo (51304)',
        'Glina (44400)',
        'Gola (48331)',
        'Golubić (22301)',
        'Gomirje (51327)',
        'Gora (44251)',
        'Gorica Svetojanska (10453)',
        'Goričan (40324)',
        'Gorjani (31422)',
        'Gornja Bučica (44425)',
        'Gornja Rijeka (48268)',
        'Gornja Stubica (49245)',
        'Gornja Vrba (35207)',
        'Gornje Bazje (33407)',
        'Gornje Dubrave (47263)',
        'Gornje Jesenje (49233)',
        'Gornje Zagorje (47307)',
        'Gornji Bogićevci (35429)',
        'Gornji Draganec (43245)',
        'Gornji Klasnić (44404)',
        'Gornji Stupnik (10255)',
        'Gospić (53000)',
        'Goveđari (20226)',
        'Grab (21242)',
        'Graberje Ivaničko (10313)',
        'Graboštani (44434)',
        'Grabovac (21271)',
        'Gračac (23440)',
        'Gračišće (52403)',
        'Gradac (21330)',
        'Gradec (10345)',
        'Gradina (33411)',
        'Gradište (32273)',
        'Grižane (51244)',
        'Grohote (21430)',
        'Grožnjan (Grisignana) (52429)',
        'Grubišno Polje (43290)',
        'Gruda (20215)',
        'Gudovac (43251)',
        'Gundinci (35222)',
        'Gunja (32260)',
        'Gušće (44203)',
        'Gvozd (44410)',
        'Hajtić (44401)',
        'Hercegovac (43284)',
        'Hlebine (48323)',
        'Hraščina-Trgovišće (49283)',
        'Hreljin (51226)',
        'Hrvace (21233)',
        'Hrvatska Dubica (44450)',
        'Hrvatska Kostajnica (44430)',
        'Hrvatski Leskovac (10251)',
        'Hum na Sutli (49231)',
        'Hvar (21450)',
        'Ičići (51414)',
        'Igrane (21329)',
        'Ilača (32248)',
        'Ilok (32236)',
        'Ilovik (51552)',
        'Imotski (21260)',
        'Ist (23293)',
        'Ivanec (42240)',
        'Ivanić-Grad (10310)',
        'Ivankovo (32281)',
        'Ivanovo Selo (43504)',
        'Ivanska (43231)',
        'Ivanja Reka (10373)',
        'Jablanac (53287)',
        'Jabukovac (44204)',
        'Jadranovo (51264)',
        'Jagodnjak (31324)',
        'Jakovlje (10297)',
        'Jakšić (34308)',
        'Jalžabet (42203)',
        'Janjina (20246)',
        'Jarmina (32280)',
        'Jasenak (47314)',
        'Jasenovac (44324)',
        'Jastrebarsko (10450)',
        'Jelsa (21465)',
        'Jesenice (21314)',
        'Jezera (22242)',
        'Jezerane (53262)',
        'Josipdol (47303)',
        'Josipovac (31221)',
        'Jurdani (51213)',
        'Kaldrma (23446)',
        'Kali (23272)',
        'Kalinovac (48361)',
        'Kalnik (48269)',
        'Kalje (10456)',
        'Kamanje (47282)',
        'Kamenmost (21262)',
        'Kanfanar (52352)',
        'Kaniška Iva (43283)',
        'Kapela (43203)',
        'Kaprije (22235)',
        'Kaptol (34334)',
        'Karanac (31315)',
        'Karin (23452)',
        'Karlobag (53288)',
        'Karlovac (47000)',
        'Karojba (52423)',
        'Kastav (51215)',
        'Kašina (10362)',
        'Kašt (47284)',
        'Kaštel Gomilica (21213)',
        'Kaštel Kambelovac (21214)',
        'Kaštel Lukšić (21215)',
        'Kaštel Stari (21216)',
        'Kaštel Sućurac (21212)',
        'Kaštelir (52464)',
        'Kijevo (22310)',
        'Kistanje (22305)',
        'Klana (51217)',
        'Klanac (53212)',
        'Klanjec (49290)',
        'Klek (20356)',
        'Klenovica (51252)',
        'Klenovnik (42244)',
        'Klis (21231)',
        'Kloštar Ivanić (10312)',
        'Kloštar Podravski (48362)',
        'Kloštar Vojakovački (48264)',
        'Kneževi Vinogradi (31309)',
        'Kneževo (31302)',
        'Knin (22300)',
        'Kolan (23251)',
        'Koločep (20221)',
        'Komin (10383)',
        'Komin (Dalmacija) (20344)',
        'Komiža (21485)',
        'Komletinci (32253)',
        'Kompolje (53226)',
        'Končanica (43505)',
        'Konjščina (49282)',
        'Koprivnica (48000)',
        'Koprivnički Bregi (48324)',
        'Koprivnički Ivanec (48314)',
        'Korčula (20260)',
        'Korenica (53230)',
        'Kornić (51517)',
        'Koromačno (52222)',
        'Kosinj (53203)',
        'Kosovo/Zvjerinac/ (22312)',
        'Kostanje (21207)',
        'Kostanjevac (10455)',
        'Kostrena (51221)',
        'Koška (31224)',
        'Kotoriba (40329)',
        'Kraljevec na Sutli (49294)',
        'Kraljevica (51262)',
        'Krapina (49000)',
        'Krapinske Toplice (49217)',
        'Krapje (44325)',
        'Krasica (51224)',
        'Krasno (53274)',
        'Krašić (10454)',
        'Kratečko (44213)',
        'Kravarsko (10413)',
        'Krivi Put (53271)',
        'Krivodol (21263)',
        'Križ (10314)',
        'Križevci (48260)',
        'Križišće (51241)',
        'Križpolje (53261)',
        'Krk (51500)',
        'Krnica (52208)',
        'Krnjak (47242)',
        'Krstinja (47221)',
        'Kršan (52232)',
        'Kučiće (21208)',
        'Kućište (20267)',
        'Kukljica (23271)',
        'Kukuljanovo (51227)',
        'Kula Norinska (20341)',
        'Kumrovec (49295)',
        'Kuna (20243)',
        'Kunovec (48311)',
        'Kupjak (51313)',
        'Kupljenovo (10295)',
        'Kutina (44320)',
        'Kutjevo (34340)',
        'Kuzmica (34311)',
        'Kuželj (51302)',
        'Labin (52220)',
        'Lanišće (52422)',
        'Lasinja (47206)',
        'Laslovo (31214)',
        'Lastovo (20290)',
        'Lećevica (21202)',
        'Ledenice (51251)',
        'Legrad (48317)',
        'Lekenik (44272)',
        'Lepajci (49224)',
        'Lepoglava (42250)',
        'Letovanić (44271)',
        'Levanjska Varoš (31416)',
        'Lič (51323)',
        'Lička Jesenica (47305)',
        'Lički Osik (53201)',
        'Ličko Lešće (53224)',
        'Ličko Petrovo Selo (53233)',
        'Lijevi Dubrovčak (10316)',
        'Lipik (34551)',
        'Lipovac (32246)',
        'Lipovljani (44322)',
        'Livade (52427)',
        'Ližnjan (52204)',
        'Lobor (49253)',
        'Lokva Rogoznica (21317)',
        'Lokve (51316)',
        'Lonjica (10341)',
        'Lopar (51281)',
        'Lopatinec (40311)',
        'Lopud (20222)',
        'Lovas (32237)',
        'Lovinac (53244)',
        'Lovište (20269)',
        'Lovran (51415)',
        'Lovreć (21257)',
        'Lozovac (22221)',
        'Ložišća (21404)',
        'Lučko (10250)',
        'Ludbreg (42230)',
        'Lug (31328)',
        'Luka (10296)',
        'Lukač (33406)',
        'Lukoran (23274)',
        'Lukovdol (51328)',
        'Lukovo Šugarje (53289)',
        'Lumbarda (20263)',
        'Lun (53294)',
        'Lupoglav (52426)',
        'Lužani (35257)',
        'Ljubeščica (42222)',
        'Macinec (40306)',
        'Mače (49251)',
        'Magadenovac (31542)',
        'Mahično (47286)',
        'Maja (44403)',
        'Majur (44433)',
        'Makarska (21300)',
        'Mala Gradusa (44212)',
        'Mala Subotica (40321)',
        'Mali Bukovec (42231)',
        'Mali Erjavec (47281)',
        'Mali Gradac (44405)',
        'Mali Lošinj (51550)',
        'Mali Obljaj (44402)',
        'Malinska (51511)',
        'Maranovići (20224)',
        'Marčana (52206)',
        'Marija Bistrica (49246)',
        'Marija Gorica (10299)',
        'Marijanci (31555)',
        'Marina (21222)',
        'Markušica (32213)',
        'Martinska Ves (44201)',
        'Martinšćica (51556)',
        'Maruševec (42243)',
        'Matulji (51211)',
        'Mečenčani (44432)',
        'Medak (53205)',
        'Medari (35432)',
        'Medulin (52203)',
        'Metković (20350)',
        'Miholjački Poreč (31543)',
        'Mihovljan (49252)',
        'Mikleuš (33517)',
        'Milna (21405)',
        'Mimice (21318)',
        'Mirkovci (32240)',
        'Mlini (20207)',
        'Mlinište (20353)',
        'Mokošica (20236)',
        'Mokro Polje (22307)',
        'Molat (23292)',
        'Molve (48327)',
        'Momjan (Momiano) (52462)',
        'Moravice (51325)',
        'Mošćenica (44253)',
        'Mošćenička Draga (51417)',
        'Motovun (52424)',
        'Mravince (21209)',
        'Mrkopalj (51315)',
        'Mursko Središče (40315)',
        'Murter (22243)',
        'Narta (43247)',
        'Našice (31500)',
        'Nebljusi (53251)',
        'Nedelišče (40305)',
        'Nedešćina (52231)',
        'Negoslavci (32239)',
        'Neorić (21247)',
        'Nerezine (51554)',
        'Nerežišća (21423)',
        'Netretić (47271)',
        'Neviđane (23264)',
        'Nijemci (32245)',
        'Nin (23232)',
        'Nova Bukovica (33518)',
        'Nova Gradiška (35400)',
        'Nova Kapela (10343)',
        'Nova Kapela (35410)',
        'Nova Rača (43272)',
        'Nova Sela (20278)',
        'Nova Vas (52446)',
        'Novalja (53291)',
        'Novi Golubovec (49255)',
        'Novi Marof (42220)',
        'Novi Vinodolski (51250)',
        'Novigrad (Cittanova) (52466)',
        'Novigrad (Dalmacija) (23312)',
        'Novigrad Podravski (48325)',
        'Novo Čiče (10415)',
        'Novo Virje (48355)',
        'Novoselec (10315)',
        'Novska (44330)',
        'Nuštar (32221)',
        'Njivice (51512)',
        'Oborovo (10372)',
        'Obrovac (23450)',
        'Obrovac Sinjski (21241)',
        'Ogulin (47300)',
        'Oklaj (22303)',
        'Okrug Gornji (21223)',
        'Okučani (35430)',
        'Olib (23296)',
        'Omiš (21310)',
        'Omišalj (51513)',
        'Opatija (51410)',
        'Opatovac (32233)',
        'Oprisavci (35213)',
        'Oprtalj (Portole) (52428)',
        'Opuzen (20355)',
        'Orahovica (33515)',
        'Orašac (20234)',
        'Orebić (20250)',
        'Orehovec (48267)',
        'Orehovica (40322)',
        'Oriovac (35250)',
        'Orle (10411)',
        'Orljavac (34320)',
        'Orolik (32243)',
        'Oroslavje (49243)',
        'Orubica (35424)',
        'Osijek (31000)',
        'Oskorušno (20242)',
        'Ostrovo (32211)',
        'Oštarije (47302)',
        'Otišić (21235)',
        'Otočac (53220)',
        'Otok (32252)',
        'Otok (Dalmacija) (21238)',
        'Otrić (23442)',
        'Otrić Seoci (20342)',
        'Ozalj (47280)',
        'Pađene (22318)',
        'Pag (23250)',
        'Pakoštane (23211)',
        'Pakrac (34550)',
        'Pašman (23262)',
        'Pazin (52000)',
        'Perjasica (47253)',
        'Perković (22205)',
        'Perušić (53202)',
        'Peteranec (48321)',
        'Petlovac (31321)',
        'Petrčane (23231)',
        'Petrijanec (42206)',
        'Petrijevci (31208)',
        'Petrinja (44250)',
        'Petrovci (32229)',
        'Petrovsko (49234)',
        'Pićan (52332)',
        'Pirovac (22213)',
        'Pisarovina (10451)',
        'Piškorevci (31417)',
        'Pitomača (33405)',
        'Pivnica Slavonska (33533)',
        'Plaški (47304)',
        'Plavno (22317)',
        'Plešce (51303)',
        'Pleternica (34310)',
        'Plitvička Jezera (53231)',
        'Ploče (20340)',
        'Pločice (20218)',
        'Plomin (52234)',
        'Podaca (21335)',
        'Podcrkavlje (35201)',
        'Podgajci Podravski (31552)',
        'Podgora (21327)',
        'Podgorač (31433)',
        'Podlapača (53236)',
        'Podpićan (52333)',
        'Podravska Moslavina (31530)',
        'Podravske Sesvete (48363)',
        'Podstrana (21312)',
        'Podšpilje (21483)',
        'Podturen (40317)',
        'Podvinje (35107)',
        'Pokupsko (10414)',
        'Polača (23423)',
        'Polača/kod Knina/ (22302)',
        'Poličnik (23241)',
        'Poljana (34543)',
        'Popovac (31303)',
        'Popovača (44317)',
        'Poreč (52440)',
        'Posavski Bregi (10311)',
        'Posavski Podgajci (32258)',
        'Posedarje (23242)',
        'Postira (21410)',
        'Potomje (20244)',
        'Povlja (21413)',
        'Povljana (23249)',
        'Poznanovec (49222)',
        'Požega (34000)',
        'Praputnjak (51225)',
        'Pražnica (21424)',
        'Predavac (43211)',
        'Pregrada (49218)',
        'Preko (23273)',
        'Prelog (40323)',
        'Premuda (23294)',
        'Preseka (10346)',
        'Prezid (51307)',
        'Prgomelje (43252)',
        'Prgomet (21201)',
        'Pridraga (23226)',
        'Pridvorje (20217)',
        'Prigorje Brdovečko (10291)',
        'Primišlje (47244)',
        'Primorski Dolac (21227)',
        'Primošten (22202)',
        'Privlaka (32251)',
        'Privlaka (Dalmacija) (23233)',
        'Prnjavor (35216)',
        'Prvić Luka (22233)',
        'Prvić Šepurine (22234)',
        'Pučišća (21412)',
        'Pula (52104)',
        'Pula (52105)',
        'Pula (52100)',
        'Pula (52106)',
        'Punat (51521)',
        'Punitovci (31424)',
        'Pupnat (20274)',
        'Putniković (20248)',
        'Rab (51280)',
        'Rabac (52221)',
        'Račinovci (32262)',
        'Račišće (20264)',
        'Radatovići (47285)',
        'Radoboj (49232)',
        'Radovan (42242)',
        'Radučić (22304)',
        'Rajevo Selo (32261)',
        'Rajić (44323)',
        'Rakov Potok (10436)',
        'Rakovec (10347)',
        'Rakovica (47245)',
        'Rasinja (48312)',
        'Raša (52223)',
        'Ratkovica (34315)',
        'Rava (23283)',
        'Raven (48265)',
        'Ravna Gora (51314)',
        'Ražanac (23248)',
        'Rečica (47203)',
        'Reka (48305)',
        'Rešetari (35403)',
        'Retkovci (32282)',
        'Ribnik (47272)',
        'Ričice (21267)',
        'Rijeka (51000)',
        'Roč (52425)',
        'Rogotin (20343)',
        'Rogoznica (22203)',
        'Rokovci Andrijaševci (32271)',
        'Rovinj (Rovigno) (52210)',
        'Rovišće (43212)',
        'Rujevac (44437)',
        'Runović (21261)',
        'Ruščica (35208)',
        'Ružić (22322)',
        'Saborsko (47306)',
        'Sali (23281)',
        'Samobor (10430)',
        'Satnica Đakovačka (31421)',
        'Savudrija (Salvore) (52475)',
        'Seget Donji (21218)',
        'Sela (44273)',
        'Selca (21425)',
        'Selce (51266)',
        'Selci Đakovački (31415)',
        'Selnica (40314)',
        'Semeljci (31402)',
        'Senj (53270)',
        'Sestrunj (23291)',
        'Sesvete (10360)',
        'Sesvete (kod Požege) (34312)',
        'Sesvete-Kraljevec (10361)',
        'Severin (43274)',
        'Severin na Kupi (51329)',
        'Sibinj (35252)',
        'Sikirevci (35224)',
        'Silba (23295)',
        'Sinj (21230)',
        'Sirač (43541)',
        'Sisak (44000)',
        'Sisak-Caprag (44010)',
        'Siverić (22321)',
        'Sjeničak Lasinjski (47213)',
        'Skakavac (47212)',
        'Skrad (51311)',
        'Skradin (22222)',
        'Slakovci (32242)',
        'Slano (20232)',
        'Slatina (33520)',
        'Slatine (21224)',
        'Slatinski Drenovac (33516)',
        'Slavonski Brod (35000)',
        'Slavonski Brod (35105)',
        'Slavonski Brod (35106)',
        'Slavonski Kobaš (35255)',
        'Slavonski Šamac (35220)',
        'Slivno (21272)',
        'Slunj (47240)',
        'Smilčić (23424)',
        'Smiljan (53211)',
        'Smokvica (20272)',
        'Sokolovac (48306)',
        'Solin (21210)',
        'Soljani (32255)',
        'Sopje (33525)',
        'Sošice (10457)',
        'Sotin (32232)',
        'Split (21000)',
        'Sračinec (42209)',
        'Srb (23445)',
        'Srednje Prilišće (47273)',
        'Srinjine (21292)',
        'Stankovac (44412)',
        'Stankovci (23422)',
        'Stara Gradiška (35435)',
        'Stari Grad (21460)',
        'Stari Jankovci (32241)',
        'Stari Mikanovci (32284)',
        'Starigrad Paklenica (23244)',
        'Staro Petrovo Selo (35420)',
        'Staševica (20345)',
        'Staza (44221)',
        'Stobreč (21311)',
        'Stomorska (21432)',
        'Ston (20230)',
        'Strizivojna (31410)',
        'Strmec Samoborski (10434)',
        'Strmica (22311)',
        'Strošinci (32256)',
        'Stubičke Toplice (49244)',
        'Studenci (21265)',
        'Sućuraj (21469)',
        'Suhopolje (33410)',
        'Sukošan (23206)',
        'Sumartin (21426)',
        'Sunja (44210)',
        'Supetar (21400)',
        'Susak (51561)',
        'Sutivan (21403)',
        'Suza (31308)',
        'Sveta Marija (40326)',
        'Sveta Nedelja (10431)',
        'Sveti Đurđ (42233)',
        'Sveti Filip i Jakov (23207)',
        'Sveti Ilija (42214)',
        'Sveti Ivan Zelina (10380)',
        'Sveti Ivan Žabno (48214)',
        'Sveti Juraj (53284)',
        'Sveti Križ Začretje (49223)',
        'Sveti Lovreč (52448)',
        'Sveti Martin na Muri (40313)',
        'Sveti Martin pod Okićem (10435)',
        'Sveti Petar u šumi (52404)',
        'Svetvinčenat (52342)',
        'Svilaj (35215)',
        'Šandrovac (43227)',
        'Šapjane (51214)',
        'Šarengrad (32234)',
        'Šaš (44222)',
        'Šenkovec (10292)',
        'Šestanovac (21250)',
        'Šibenik (22000)',
        'Šibenik-Brodarica (22010)',
        'Šibenik-Ražine (22020)',
        'Šibenik-Zablaće (22030)',
        'Šilo (51515)',
        'Šipanjska Luka (20223)',
        'Široke (22204)',
        'Šišljavić (47204)',
        'Škabrnja (23223)',
        'Škare (53221)',
        'Škrljevo (51223)',
        'Šmrika (51263)',
        'Špišić Bukovica (33404)',
        'Štefanje (43246)',
        'Štitar (32274)',
        'Štrigova (40312)',
        'Šušnjevica (52233)',
        'Švica (53225)',
        'Tar (52465)',
        'Tenja (31207)',
        'Tijarica (21245)',
        'Tinjan (52444)',
        'Tisno (22240)',
        'Tkon (23212)',
        'Topolo (20205)',
        'Topolovac (44202)',
        'Topusko (44415)',
        'Tordinci (32214)',
        'Tounj (47264)',
        'Tovarnik (32249)',
        'Trakošćan (42254)',
        'Trget (52224)',
        'Tribalj (51243)',
        'Tribanj (23245)',
        'Tribunj (22212)',
        'Trilj (21240)',
        'Trnava (31411)',
        'Trnovec Bartolovečki (42202)',
        'Trnovitički Popovac (43233)',
        'Trnjani (35211)',
        'Trogir (21220)',
        'Trpanj (20240)',
        'Trpinja (32224)',
        'Trstenik (20245)',
        'Trsteno (20233)',
        'Tršće (51305)',
        'Tučepi (21325)',
        'Tugare (21252)',
        'Tuhelj (49215)',
        'Turčin (42204)',
        'Tušilović (47241)',
        'Udbina (53234)',
        'Ugljan (23275)',
        'Ugljane (21243)',
        'Uljanik (43507)',
        'Umag (Umago) (52470)',
        'Unešić (22323)',
        'Unije (51562)',
        'Utinja (47211)',
        'Valpovo (31550)',
        'Varaždin (42000)',
        'Varaždinske Toplice (42223)',
        'Vela Luka (20270)',
        'Vele Mune (51212)',
        'Veli Iž (23284)',
        'Veli Lošinj (51551)',
        'Veli Rat (23287)',
        'Velika (34330)',
        'Velika Gorica (10410)',
        'Velika Kopanica (35221)',
        'Velika Ludina (44316)',
        'Velika Mlaka (10408)',
        'Velika Pisanica (43271)',
        'Velika Trnovitica (43285)',
        'Veliki Bastaji (43531)',
        'Veliki Grđevac (43270)',
        'Veliki Prolog (21277)',
        'Veliki Zdenci (43293)',
        'Veliko Trgovišće (49214)',
        'Veliko Trojstvo (43226)',
        'Veliko Vukovje (43282)',
        'Veljun (47243)',
        'Vetovo (34335)',
        'Vid (20352)',
        'Vidovec (42205)',
        'Viljevo (31531)',
        'Vinica (42207)',
        'Vinišće (21226)',
        'Vinkovci (32100)',
        'Vinjerac (23247)',
        'Vir (23234)',
        'Virje (48326)',
        'Virovitica (33000)',
        'Vis (21480)',
        'Visoko (42224)',
        'Viškovci (31401)',
        'Viškovo (51216)',
        'Višnjan (52463)',
        'Višnjevac (31220)',
        'Vivodina (47283)',
        'Vižinada (52447)',
        'Vladislavci (31404)',
        'Vlahović (44406)',
        'Voćin (33522)',
        'Vodice (22211)',
        'Vodnjan (Dignano) (52215)',
        'Vođinci (32283)',
        'Vojnić (47220)',
        'Voloder (44318)',
        'Vrata (51321)',
        'Vratišinec (40316)',
        'Vratnik (53273)',
        'Vrbanj (21462)',
        'Vrbanja (32254)',
        'Vrbje (35423)',
        'Vrbnik (51516)',
        'Vrboska (21463)',
        'Vrbova (35414)',
        'Vrbovec (10340)',
        'Vrbovsko (51326)',
        'Vrgorac (21276)',
        'Vrhovine (53223)',
        'Vrlika (21236)',
        'Vrpolje (35210)',
        'Vrsar (52450)',
        'Vrsi (23235)',
        'Vuka (31403)',
        'Vukmanić (47205)',
        'Vukovar (32000)',
        'Vukovar - Lipovača (32010)',
        'Vukovina (10419)',
        'Zabok (49210)',
        'Zadar (23000)',
        'Zadvarje (21255)',
        'Zagorska Sela (49296)',
        'Zagvozd (21270)',
        'Zaostrog (21334)',
        'Zapolje (35422)',
        'Zaprešić (10290)',
        'Zastražišće (21466)',
        'Zaton (22215)',
        'Zaton Veliki (20235)',
        'Zdenci (33513)',
        'Zelčin (31227)',
        'Zemunik (23222)',
        'Zlarin (22232)',
        'Zlatar (49250)',
        'Zlatar Bistrica (49247)',
        'Zlobin (51324)',
        'Zmajevac (31307)',
        'Zmijavci (21266)',
        'Zrinski Topolovac (43202)',
        'Zrmanja (23443)',
        'Zubovići (53296)',
        'Zvečaj (47261)',
        'Žakanje (47276)',
        'Ždala (48332)',
        'Ždrelac (23263)',
        'Žegar (23451)',
        'Žirje (22236)',
        'Žman (23282)',
        'Žminj (52341)',
        'Žrnovnica (21251)',
        'Žrnovo (20275)',
        'Žuljana (20247)',
        'Župa (21273)',
        'Županja (32270)'
      ],
      allPostalWithoutIsladsCodes: [],
      allowedBarfPostalCodes: [
        'Belovar (10363)',
        'Bedenica (10381)',
        'Bestovje  (10437)',
        'Bregana (10432)',
        'Brezovica (10257)',
        'Buševec (10417)',
        'Dugo Selo (10370)',
        'Dubranec (10418)',
        'Dubrava (10342)',
        'Dubravica (10293)',
        'Donja Bistra  (10298)',
        'Donja Lomnica (10412)',
        'Donja Pušća (10294)',
        'Donja Zelina (10382)',
        'Donji Dragonožec (10253)',
        'Farkaševac (10344)',
        'Gorica Svetojanska (10453)',
        'Gornji Stupnik (10255)',
        'Gradec (10345)',
        'Graberje Ivaničko (10313)',
        'Hrvatski Leskovac (10251)',
        'Ivanja Reka (10373)',
        'Ivanić-Grad (10310)',
        'Jakovlje (10297)',
        'Jastrebarsko (10450)',
        'Kalje (10456)',
        'Kašina (10362)',
        'Kloštar Ivanić (10312)',
        'Komin (10383)',
        'Kostanjevac (10455)',
        'Krašić (10454)',
        'Križ (10314)',
        'Kravarsko (10413)',
        'Kupljenovo (10295)',
        'Lijevi Dubrovčak (10316)',
        'Lonjica (10341)',
        'Luka (10296)',
        'Lučko (10250)',
        'Marija Gorica (10299)',
        'New Čiče (10415)',
        'Nova Kapela (10343)',
        'Novo Čiče (10415)',
        'Novoselec (10315)',
        'Oborovo (10372)',
        'Orle (10411)',
        'Pisarovina (10451)',
        'Pokupsko (10414)',
        'Posavski Bregi (10311)',
        'Prigorje Brdovečko (10291)',
        'Rakovec (10347)',
        'Rakov Potok (10436)',
        'Samobor (10430)',
        'Sesvete (10360)',
        'Sesvete-Kraljevec (10361)',
        'Sošice (10457)',
        'Strmec Samoborski (10434)',
        'Sveta Nedjelja (10431)',
        'Sveti Ivan Zelina (10380)',
        'Sveti Martin pod Okićem (10435)',
        'Šenkovec (10292)',
        'Šenkovec (10292)',
        'Velika Gorica (10410)',
        'Velika Mlaka (10408)',
        'Vrbovec (10340)',
        'Vukovina (10419)',
        'Zagreb (10000)',
        'Zagreb-Dubrava (10040)',
        'Zagreb-Novi Zagreb (10020)',
        'Zagreb-Sloboština (10010)',
        'Zagreb-Susedgrad (10090)',
        'Zagreb-Trešnjevka (10110)',
        'Zaprešić (10290)',
      ],
      allowedIslandsPostalCodes: [
        'Babino Polje (20225)',
        'Bol (21420)',
        'Božava (23286)',
        'Brbinj (23285)',
        'Blato (20271)',
        'Cres (51557)',
        'Drvenik Veliki (21225)',
        'Grohote (21430)',
        'Hvar (21450)',
        'Ilovik (51552)',
        'Ist (23293)',
        'Jelsa (21465)',
        'Kaprije (22235)',
        'Koločep (20221)',
        'Komiža (21485)',
        'Kornati (22243)',
        'Korčula (20260)',
        'Kukljica (23271)',
        'Lastovo (20290)',
        'Lopud (20222)',
        'Mali Lošinj (51550)',
        'Martinšćica (51556)',
        'Milna (21405)',
        'Molat (23292)',
        'Olib (23296)',
        'Preko (23273)',
        'Prvić Luka (22233)',
        'Prvić Šepurine (22234)',
        'Pučišća (21412)',
        'Rab (51280)',
        'Rava (23283)',
        'Selca (21425)',
        'Sali (23281)',
        'Silba (23295)',
        'Sestrunj (23291)',
        'Stari Grad (21460)',
        'Sućuraj (21469)',
        'Supetar (21400)',
        'Susak (51561)',
        'Šipanska Luka (20223)',
        'Žman (23282)',
        'Žirje (22236)',
        'Tkon (23212)',
        'Unije (51562)',
        'Vela Luka (20270)',
        'Veli Iž (23284)',
        'Veli Rat (23287)',
        'Vis (21480)',
        'Vrgada (23210)',
        'Zlarin (22232)',
        'Šipanska Luka (20223)',
      ],
      selectedPostalCode: 'Molimo odaberite mjesto',

      address: null,

      canOrderBeCompleted: false,
      showBarfMsg: false,


      minimalOrderCost: null,
      deliveryType: null,
      deliveryCost: null,
      availablePayments: [],

      removeBarfUrl: null,

      validationMsg: null
    }
  },
  mounted() {
    this.ids = this.productIds.split(",");
    this.barfIds = this.barfProductIds.split(",");
    this.barfInCart = this.isBarfProductInCart === '1';

    this.removeBarfUrl = `${window.location.origin}/api/v1/orders/remove-item/` + this.barfProductIds

    this.allPostalWithoutIsladsCodes = this.allPostalCodes.filter((el) => {
      return !this.allowedIslandsPostalCodes.includes(el);
    });

    // if BARF product/s in cart
    if (this.isBarfProductInCart === '1') {

      this.countries = this.barfCountries
      this.postalCodes = this.allowedBarfPostalCodes
      this.showBarfMsg = true

      this.selectedCountry = 'Hrvatska'
      this.minimalOrderCost = 26
      this.deliveryType = 'Vlastita dostava'
      this.deliveryCost = 0
      this.availablePayments = ['invoice', 'cashOnDelivery', 'creditCard']

    } else {
      this.countries = this.allCountries
      this.postalCodes = this.allPostalCodes
    }
  },
  methods: {
    onSelectCountryChange(event) {
      if (!this.barfInCart && event.target.value === 'Hrvatska') {

        this.minimalOrderCost = 0
        this.deliveryType = 'DPD'
        this.availablePayments = ['creditCard']

      } else if (!this.barfInCart && event.target.value !== 'Hrvatska') {
        // delivery in croatia
        this.minimalOrderCost = 50
        this.deliveryType = 'DPD'
        this.deliveryCost = '0'
        this.availablePayments = ['creditCard']
      }
    },
    onSelectedPostalCodeChange(event) {

      //
      // Cijela Hrvatska (uključujući Zagreb i zag. županiju, isključujući mjesta spopisa* mjesta na otocima)
      //
      if (this.allPostalWithoutIsladsCodes.includes(event.target.value)) {
        if (parseFloat(this.cartTotal) < '35') {
          this.deliveryCost = '5.50'
        } else {
          this.deliveryCost = '0'
        }
        if (this.isBarfProductInCart) {
          this.deliveryType = 'Vlastita dostava'
          this.deliveryCost = '0'
        }
        this.minimalOrderCost = 0
      } else if (this.allowedIslandsPostalCodes.includes(event.target.value)) {
        if (parseFloat(this.cartTotal) < '50') {
          this.deliveryCost = '5.50'
        } else {
          this.deliveryCost = '0'
        }
        this.minimalOrderCost = 0
      }
    },
    finishOrder() {
      if (this.selectedCountry !== 'Hrvatska' && this.selectedCountry !== 'Molimo odaberite državu') {
        // Not HR
        if (this.place && this.address) {
          this.validationMsg = null
          Vue.ajax({
            url: "/update-cart",
            method: "post",
            data: {
              'country' : this.selectedCountry,
              'city' : this.place, // this.selectedPostalCode
              'address' : this.address,
              'deliveryCost' : this.deliveryCost,
              'deliveryType' : this.deliveryType,
              'availablePayments' : this.availablePayments
            }
          }).then(function(response) {
            window.location.href = response.data.data.redirectToRoute.targetUrl;
          }, function(response) {
          })
        } else {
          this.validationMsg = 'Molimo popunite sva obavezna polja označena sa *'
        }
      } else {
        // HR
        if (this.selectedCountry === 'Hrvatska' && this.selectedPostalCode !== 'Molimo odaberite mjesto') {
          if (this.selectedPostalCode && this.address) {
            this.validationMsg = null
            Vue.ajax({
              url: "/update-cart",
              method: "post",
              data: {
                'country' : this.selectedCountry,
                'city' : this.selectedPostalCode, // this.selectedPostalCode
                'address' : this.address,
                'deliveryCost' : this.deliveryCost,
                'deliveryType' : this.deliveryType,
                'availablePayments' : this.availablePayments
              }
            }).then(function(response) {
              window.location.href = response.data.data.redirectToRoute.targetUrl;
            }, function(response) {
            })
          } else {
            this.validationMsg = 'Molimo popunite sva obavezna polja označena sa *'
          }
        } else {
          this.validationMsg = 'Molimo popunite sva obavezna polja označena sa *'
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~include-media/dist/include-media";
@import "../../../../styles/helpers/_variables";
@import "../../../../styles/helpers/mixins";

.delivery-form {
  .d-title {
    margin: 0;
    padding: 0;
    display: inline-block;
  }
}
</style>

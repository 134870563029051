import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default class FeaturedProductCarousel {
    constructor() {
        this.onInit()
    }

    onInit() {
        new Swiper('.js-featured-product-swiper', {
            modules: [Pagination],
            direction: 'horizontal',
            slidesPerView: 4,
            spaceBetween: 30,
            initialSlide: 0,
            autoHeight: true,
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                576: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
        });
    }
}

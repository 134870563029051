import Vue from 'vue'
import ajax from "vuejs-ajax"
import WhereToBuy from "./components/where-to-buy/WhereToBuy";
import DeliveryForm from "./components/delivery-form/DeliveryForm.vue";

Vue.use(ajax)

export default new Vue({
    el: '#barf_app',
    components: {
        'where-to-buy': WhereToBuy,
        'delivery-form': DeliveryForm,
    }
});

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "delivery-form", attrs: { id: "delivery-form" } },
    [
      _vm.showBarfMsg
        ? _c("div", { staticClass: "delivery__alert" }, [
            _c("p", [
              _vm._v(
                "Za sada su BARF (smrznuti) proizvodi dostupni za narudžbu na području Zagreba i Zagrebačke županije."
              ),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Kako bi nastavili svoju kupnju, potrebno je ukloniti BARF smrznute proizvod iz košarice.\n    Dok mi rješavamo dostavu smrznutog dijela asortimana, neka vaš pas krati\n    vrijeme i uživa žvačući naše prirodne i slasne poslastice.“"
              ),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("a", { attrs: { href: _vm.removeBarfUrl } }, [
              _vm._v("Ukloni BARF smrznute proizvode"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "s-form-group" }, [
        _c(
          "label",
          {
            staticClass: "s-form-group__label",
            attrs: { for: "checkout_place" },
          },
          [_vm._v("Država *")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedCountry,
                expression: "selectedCountry",
              },
            ],
            staticClass: "s-form-group__input",
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedCountry = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                function ($event) {
                  return _vm.onSelectCountryChange($event)
                },
              ],
            },
          },
          [
            _c("option", { attrs: { disabled: "" } }, [
              _vm._v("Molimo odaberite državu"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.countries, function (country, key) {
              return _c("option", { key: key }, [
                _vm._v("\n        " + _vm._s(country)),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.selectedCountry === "Hrvatska"
        ? _c("div", { staticClass: "s-form-group" }, [
            _c(
              "label",
              {
                staticClass: "s-form-group__label",
                attrs: { for: "checkout_place" },
              },
              [_vm._v("Mjesto *")]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedPostalCode,
                    expression: "selectedPostalCode",
                  },
                ],
                staticClass: "s-form-group__input",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedPostalCode = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.onSelectedPostalCodeChange($event)
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { disabled: "" } }, [
                  _vm._v("Molimo odaberite mjesto"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.postalCodes, function (postalCode, key) {
                  return _c("option", { key: key }, [
                    _vm._v("\n        " + _vm._s(postalCode)),
                  ])
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedCountry !== "Hrvatska" &&
      _vm.selectedCountry !== "Molimo odaberite državu"
        ? _c("div", { staticClass: "s-form-group" }, [
            _c(
              "label",
              {
                staticClass: "s-form-group__label",
                attrs: { for: "checkout_place" },
              },
              [_vm._v("Mjesto *")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.place,
                  expression: "place",
                },
              ],
              staticClass: "s-form-group__input",
              attrs: {
                type: "text",
                required: "required",
                placeholder: "Unesite vaš poštanski broj",
              },
              domProps: { value: _vm.place },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.place = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "s-form-group" }, [
        _c(
          "label",
          {
            staticClass: "s-form-group__label",
            attrs: { for: "checkout_place" },
          },
          [_vm._v("Adresa *")]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.address,
              expression: "address",
            },
          ],
          staticClass: "s-form-group__input",
          attrs: {
            type: "text",
            required: "required",
            placeholder: "Unesite vašu adresu dostave",
          },
          domProps: { value: _vm.address },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.address = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _vm.validationMsg
        ? _c("div", { staticClass: "delivery__alert" }, [
            _c("p", [_vm._v(_vm._s(_vm.validationMsg))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "s-form-group" }, [
        parseFloat(_vm.minimalOrderCost) >= parseFloat(_vm.cartTotal)
          ? _c(
              "a",
              {
                staticStyle: { "margin-right": "calc(100% - 390px)" },
                attrs: { href: "/proizvodi" },
              },
              [_vm._v("Povratak na listu proizvoda")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "vb-button vb-button--go-to-checkout",
            attrs: {
              disabled:
                parseFloat(_vm.minimalOrderCost) >= parseFloat(_vm.cartTotal),
            },
            on: { click: _vm.finishOrder },
          },
          [_c("strong", [_vm._v("Dovršite narudžbu")])]
        ),
      ]),
      _vm._v(" "),
      parseFloat(_vm.minimalOrderCost) >= parseFloat(_vm.cartTotal)
        ? _c("div", { staticClass: "delivery__alert" }, [
            _c("p", [
              _vm._v(
                "Moguća narudžba samo za kupovinu iznad " +
                  _vm._s(parseFloat(_vm.minimalOrderCost)) +
                  " €"
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
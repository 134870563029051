import './styles/main.scss';
import './scripts/main';
import * as CookieConsent from "vanilla-cookieconsent";
import { gtag } from "ga-gtag";
import $ from 'jquery'

document.getElementById('js-mobile-menu').addEventListener('change', function () {
    const mobileClass = '.r-mobile-aside';
    const mobileAside = document.querySelector(mobileClass);
    mobileAside.classList.toggle('r-mobile-aside--show');
});

document.querySelector('.js-toggle-lang-menu').addEventListener('click', function () {
    const menuClass = '.lang-menu__items';
    const langMenu = document.querySelector(menuClass);
    langMenu.classList.toggle('lang-menu__items--open');
});

const productPackagingVariationsSelect = document.getElementById('productPackagingVariations');
if (productPackagingVariationsSelect) {
    productPackagingVariationsSelect.addEventListener('change', function() {
        window.location.href = window.location.origin + this.value;
    });
}

const menuItems = document.querySelectorAll('.main-menu__item--have-submenu');
menuItems.forEach(item => {
    item.addEventListener('mouseenter', function () {
        const submenu = this.querySelector('.main-menu__submenu');
        if (submenu) {
            submenu.classList.add('main-menu__submenu--show');
        }
    });

    item.addEventListener('mouseleave', function () {
        const submenu = this.querySelector('.main-menu__submenu');
        if (submenu) {
            submenu.classList.remove('main-menu__submenu--show');
        }
    });
});

const submenuItems = document.querySelectorAll('.mobile-main-menu__item--have-submenu');
submenuItems.forEach(item => {
    item.addEventListener('click', function () {
        const submenu = this.querySelector('.mobile-main-menu__submenu');
        if (submenu) {
            submenu.classList.add('mobile-main-menu__submenu--show');
        }
    });

    item.addEventListener('mouseleave', function () {
        const submenu = this.querySelector('.mobile-main-menu__submenu');
        if (submenu) {
            submenu.classList.remove('mobile-main-menu__submenu--show');
        }
    });
});

document.querySelectorAll('.js-add-to-cart').forEach(function (element) {
    element.addEventListener('click', function (e) {
        e.preventDefault();

        const productId = this.getAttribute('data-id');
        if (!productId) {
            return; // Exit if data-id attribute is not present
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${window.location.origin}/add-product`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                location.reload();
            }
        };

        xhr.send('id=' + encodeURIComponent(productId));
    });
});

if (document.getElementById('checkout_haveR1')) {
    document.getElementById('checkout_haveR1').addEventListener('change', function () {
        const companyId = document.getElementById('checkout_company');
        const companyOibId = document.getElementById('checkout_companyOib');

        if (companyId && companyOibId) {
            companyId.classList.toggle('s-form-group__input--hide');
            companyOibId.classList.toggle('s-form-group__input--hide');
            companyId.focus();
        }
    });
}

document.querySelectorAll('.js-minus, .js-plus').forEach(function (element) {
    element.addEventListener('click', function () {
        document.getElementById('cart_save').click();
    });
});

document.querySelectorAll('.js-quick-cart').forEach(function (element) {
    element.addEventListener('click', function () {
        document.querySelector('.quick-cart').classList.toggle('quick-cart--open');
    });
});



const body = document.body;
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;

window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
        body.classList.remove(scrollUp);
        return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
    } else if (
        currentScroll < lastScroll &&
        body.classList.contains(scrollDown)
    ) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
    }
    lastScroll = currentScroll;
});


$('.active-coupon-remove').on('click tap', () => {
    $.ajax(
        {
            url: window.location.origin + "/kosarica/remove-coupon",
            success: function(result){
                window.location.reload();
            }
        });
})


CookieConsent.run({
    disablePageInteraction: true,
    cookie: {
        name: 'cc_cookie',
        domain: location.hostname,
    },
    guiOptions: {
        consentModal: {
            layout: 'inline',
            position: 'bottom right',
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: 'box',
            equalWeightButtons: true,
            flipButtons: false
        }
    },

    onConsent: ({cookie}) => {
        if (cookie.categories.length === 3 &&
            cookie.categories.includes('necessary') &&
            cookie.categories.includes('analytics') &&
            cookie.categories.includes('ads')) {
            gtag('consent', 'update', {
                "security_storage": "granted",
                "functionality_storage": "granted",
                "analytics_storage": "granted",
                "personalization_storage": "granted",
                "ad_storage": "granted",
                "ad_user_data": "granted",
                "ad_personalization": "granted",
            });
        } else if(cookie.categories.length === 2 &&
            cookie.categories.includes('necessary') &&
            cookie.categories.includes('analytics')) {
            gtag('consent', 'update', {
                "security_storage": "granted",
                "functionality_storage": "granted",
                "analytics_storage": "granted",
                "personalization_storage": "granted",
            });
        } else if(cookie.categories.length === 2 &&
            cookie.categories.includes('necessary') &&
            cookie.categories.includes('ads')) {
            gtag('consent', 'update', {
                "security_storage": "granted",
                "functionality_storage": "granted",
                "analytics_storage": "granted",
                "ad_storage": "granted",
                "ad_user_data": "granted",
                "ad_personalization": "granted",
            });
        } else if(cookie.categories.length === 1 &&
            cookie.categories.includes('necessary')) {
            gtag('consent', 'update', {
                "security_storage": "granted",
                "functionality_storage": "granted",
                "analytics_storage": "granted",
            });
        }
    },

    onModalReady: ({modalName}) => {
        if (typeof gtag == "undefined") {
            function gtag(){dataLayer.push(arguments);}
        }
        gtag('consent', 'default', {
            "security_storage": "default",
            "functionality_storage": "default",
            "analytics_storage": "default",
            "personalization_storage": "default",
            "ad_storage": "default",
            "ad_user_data": "default",
            "ad_personalization": "default",
        });
    },
    categories: {
        necessary: {
            enabled: true,
            readOnly: true
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^_ga/,
                    },
                    {
                        name: '_gid',
                    }
                ]
            },

            services: {
                ga: {
                    label: 'Google Analytics',
                    onAccept: () => {},
                    onReject: () => {}
                },
            }
        },
        ads: {}
    },

    language: {
        default: 'en',
        translations: {
            en: {
                consentModal: {
                    title: 'Koristimo kolačiće',
                    description: `Ova stranica koristi kolačiće. Neki od ovih kolačića su neophodni, dok nam drugi pomažu da poboljšamo vaše iskustvo dajući uvid u to kako se stranica koristi. Za detaljnije informacije o kolačićima koje koristimo, pogledajte na stranici <a href="/izjava-o-privatnosti/" target="_blank">Politika privatnosti.</a>`,
                    acceptAllBtn: 'Prihvatiti sve',
                    acceptNecessaryBtn: 'Odbaci sve',
                    showPreferencesBtn: 'Upravljanje individualnim preferencijama',
                },
                preferencesModal: {
                    title: 'Upravljajte postavkama kolačića',
                    acceptAllBtn: 'Prihvatiti sve',
                    acceptNecessaryBtn: 'Odbaci sve',
                    savePreferencesBtn: 'Prihvati trenutni odabir',
                    closeIconLabel: 'Zatvori modal',
                    serviceCounterLabel: 'Usluga|Usluge',
                    sections: [
                        {
                            title: 'Vaš izbor privatnosti',
                            description: `U ovoj ploči možete izraziti neke preferencije vezane uz obradu vaših osobnih podataka. Možete pregledati i promijeniti izražene izbore u bilo kojem trenutku ponovnim pojavljivanjem ove ploče putem navedene veze. Kako biste odbili svoj pristanak na određene aktivnosti obrade opisane u nastavku, isključite preklopnike ili upotrijebite gumb "Odbaci sve" i potvrdite da želite spremiti svoje odabire.`,
                        },
                        {
                            title: 'Neophodni',
                            description: 'Ovi su kolačići neophodni za pravilno funkcioniranje web stranice i ne mogu se onemogućiti.',
                            linkedCategory: 'necessary'
                        },
                        {
                            title: 'Analitika',
                            description: 'Ovi kolačići prikupljaju informacije o tome kako koristite našu web stranicu. Svi podaci su anonimizirani i ne mogu se koristiti za vašu identifikaciju.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                caption: 'Cookie table',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Description'
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        domain: location.hostname,
                                        desc: '',
                                    },
                                    {
                                        name: '_gid',
                                        domain: location.hostname,
                                        desc: '',
                                    }
                                ]
                            }
                        },
                        {
                            title: 'Marketing',
                            description: 'Ovi se kolačići koriste kako bi oglasne poruke bile relevantnije za vas i vaše interese. Namjera je prikazati oglase koji su relevantni i privlačni za pojedinačnog korisnika, a time i vrijedniji za izdavače i oglašivače treće strane.',
                            linkedCategory: 'ads',
                        },
                        {
                            title: 'Više informacija',
                            description: 'Za sve upite u vezi s mojim pravilima o kolačićima i vašim izborima, molimo <a href="/kontakt">kontaktirajte nas</a>'
                        }
                    ]
                }
            }
        }
    }
});

export default class MobileAccordion {
    constructor(selector) {
        this.selector = selector;
        this.contentElement = selector.nextElementSibling;
        this.showClass = 'mobile-accordion__content--show';

        selector.addEventListener("click", () => this.show());
        const closeElement = this.contentElement.querySelector('.mobile-accordion__content-close');
        closeElement.addEventListener("click", () => this.hide());
    }

    show() {
        this.contentElement.classList.add(this.showClass);
    }

    hide() {
        this.contentElement.classList.remove(this.showClass);
    }
}

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export default class SingleProductCarousel {
    constructor() {
        this.onInit()
    }

    onInit() {
        new Swiper('.product-carousel .swiper', {
            modules: [Navigation, Pagination],
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 0,
            initialSlide: 0,
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                // when window width is >= 480px
                576: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

        const lightbox = new PhotoSwipeLightbox({
            gallery: '#my-gallery',
            children: 'a',
            pswpModule: () => import('photoswipe')
        });
        lightbox.init()
    }
}

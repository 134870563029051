var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "500px", width: "100%" } },
    [
      _vm.showMap
        ? _c(
            "l-map",
            {
              staticStyle: { height: "80%" },
              attrs: {
                zoom: _vm.zoom,
                center: _vm.center,
                options: _vm.mapOptions,
              },
              on: {
                "update:center": _vm.centerUpdate,
                "update:zoom": _vm.zoomUpdate,
              },
            },
            [
              _c("v-geosearch", { attrs: { options: _vm.geosearchOptions } }),
              _vm._v(" "),
              _c("l-tile-layer", {
                attrs: { url: _vm.url, attribution: _vm.attribution },
              }),
              _vm._v(" "),
              _vm._l(_vm.stores, function (item, index) {
                return _vm.isStoresLoaded
                  ? _c(
                      "l-marker",
                      {
                        key: index,
                        attrs: { "lat-lng": item.latLng, icon: _vm.icon },
                      },
                      [
                        _c("l-popup", [
                          _c("div", [_c("b", [_vm._v(_vm._s(item.title))])]),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(item.street))]),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(item.city))]),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(item.phone))]),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(item.email))]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e()
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }